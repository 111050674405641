import React from 'react';
import block from 'bem-cn';

/* import spinnerIMG from './img/spinner.png'; */

import './Spinner.scss';

const b = block('spinner-desktop');

const Spinner = ({ isLoading, foolViewport }) => {
  return isLoading ? (
    <div className={b({ foolViewport })}>
      <div class={b('container')}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      {/* <img alt="spinner" className={b('logo')} src={spinnerIMG} /> */}
    </div>
  ) : null;
};

export default Spinner;
