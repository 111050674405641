import React, { useState, useMemo } from 'react';
import block from 'bem-cn';
import { Link } from 'react-router-dom';

import SVG from 'components/SVG';

import actionsSVG from './img/actions.svg';

import './Dropdown.scss';

const b = block('dropdown');

const Dropdown = ({ items, locale, user, isMobile = false }) => {
  const [isOpen, changeOpen] = useState(false);

  const itemsList = useMemo(
    () =>
      items?.map(item => (
        <Link to={`${item.link}/${user.id}`} key={item.name} className={b('item')}>
          <SVG className={b('item-icon').toString()} svgProps={{ svg: item.svg }} />
          <span className={b('item-value')}>{locale[item.title]}</span>
        </Link>
      )),
    [items, locale],
  );

  return (
    <div className={b({ open: isOpen, isMobile })} onClick={() => changeOpen(!isOpen)}>
      <div className={b('action')}>
        <SVG className={b('action-icon').toString()} svgProps={{ svg: actionsSVG }} />
      </div>
      {isOpen && (
        <ul className={b('items')} onMouseLeave={() => changeOpen(false)}>
          {itemsList}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
