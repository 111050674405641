import React from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';

import ProviderIcon from 'components/ProviderIcon';

import './TableRow.scss';

const TableRow = ({ items }) => {
  const b = block('table-providers-row ');
  return (
    <div className={b('tr')}>
      {items?.map((el, index) => (
        <div key={`${el}_${index}`} className={b('td')}>
          {index === 0 && <ProviderIcon className={b('item-icon')} name={el.toLowerCase()} />}
          {el}
        </div>
      ))}
    </div>
  );
};

TableRow.propTypes = {
  items: PropTypes.array.isRequired,
};

export default TableRow;
