import allUsersSVG from '../img/allUsers.svg';
import agentsSVG from '../img/agents.svg';
import playersSVG from '../img/players.svg';
import disabledUserSVG from '../img/disabledUser.svg';

export const tabLinks = [
  {
    name: 'allUsers',
    link: '/users/all',
    icon: allUsersSVG,
  },
  {
    name: 'agents',
    link: '/users/agents',
    icon: agentsSVG,
  },
  {
    name: 'players',
    link: '/users/players',
    icon: playersSVG,
  },
  {
    name: 'disabled',
    link: '/users/disabled',
    icon: disabledUserSVG,
  },
];
