import { addNotify } from 'features/notify';

import { actions } from 'features/Auth';

const actionTypes = {
  ACTION_PROCESSING: 'user/ACTION_PROCESSING',
  CREATE_USER_SUCCESS: 'user/CREATE_USER_SUCCESS',
  GET_USERS_LIST_SUCCESS: 'user/GET_USER_LIST_SUCCESS',
  GET_USERS_LIST_FAILED: 'user/GET_USER_LIST_FAILED',
  RESET_PASSWORD_SUCCESS: 'user/RESET_PASSWORD_SUCCESS',
  GET_USER_INFO_SUCCESS: 'user/GET_USER_INFO_SUCCESS',
  USER_INFO_DELETE: 'user/USER_INFO_DELETE',
  DATA_REFRESH: 'user/DATA_REFRESH',
};

function createUser({ form, callback }) {
  return async (dispatch, getState, extra) => {
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
    const { api } = extra;
    const { locale } = getState().locale;
    const { username, password, email, name, surname } = form;
    const requestBody = {
      username,
      password,
      email,
      first_name: name,
      last_name: surname,
      role: 0, // роль для создания юзера
    };

    if (requestBody.password.length < 1 || requestBody.username.length < 1) {
      dispatch(addNotify('Password and username shoud not be empty', 'error'));
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    } else {
      const response = await api.user.createUser(requestBody);
      if (response.success) {
        dispatch(addNotify(locale.createSuccess, 'success'));
        dispatch({ type: actionTypes.CREATE_USER_SUCCESS });
        dispatch({ type: actionTypes.DATA_REFRESH, payload: true });
        callback && callback();
      } else {
        dispatch(addNotify(response.errorMessage, 'error'));
        dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
      }
    }
  };
}

function createAgent({ form, callback }) {
  return async (dispatch, getState, extra) => {
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
    const { api } = extra;
    const { locale } = getState().locale;
    const { username, password, email, name, surname, withdrawalAllowed, depositAllowed } = form;
    const requestBody = {
      username,
      password,
      email,
      first_name: name,
      last_name: surname,
      is_withdrawal_access: withdrawalAllowed,
      is_deposit_access: depositAllowed,
      role: 6, // роль для создания агента
    };

    if (requestBody.password.length < 1 || requestBody.username.length < 1) {
      dispatch(addNotify('password and username shoud not be empty', 'error'));
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    } else {
      const response = await api.user.createUser(requestBody);
      if (response.success) {
        dispatch(addNotify(locale.createSuccess, 'success'));
        dispatch({ type: actionTypes.CREATE_USER_SUCCESS });
        dispatch({ type: actionTypes.DATA_REFRESH, payload: true });
        callback && callback();
      } else {
        dispatch(addNotify(response.errorMessage, 'error'));
        dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
      }
    }
  };
}

function getUsersList(params, withName = false) {
  return async (dispatch, getState, extra) => {
    const { api } = extra;
    const { locale } = getState().locale;
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
    const response = await api.user.getUsersList(params);
    if (response.success) {
      const parentUser = response.data?.users?.[0];

      dispatch({
        type: actionTypes.GET_USERS_LIST_SUCCESS,
        payload: response.data,
      });
      return withName ? { id: parentUser?.id, username: parentUser?.username} : parentUser?.id;
    } else {
      dispatch(addNotify(locale.userNotFound, 'error'));
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
      dispatch({ type: actionTypes.GET_USERS_LIST_FAILED });
      // return new Promise();
    }
  };
}

function changeBanState(id, value) {
  return async (dispatch, getState, extra) => {
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
    const { api } = extra;
    const { locale } = getState().locale;
    const response = await api.user.changeBanState(id, value);
    const text = value ? locale.banSuccess : locale.unbanSuccess;
    if (response.success) {
      dispatch(addNotify(text, 'success'));
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    } else {
      dispatch(addNotify('Error', 'error'));
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    }
  };
}

function resetPassword(id, callback, new_password) {
  return async (dispatch, getState, extra) => {
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
    const { api } = extra;
    const { locale } = getState().locale;
    const response = await api.user.resetPassword(id, new_password);
    if (response.success) {
      callback(true);
      dispatch(addNotify(locale.resetSuccess, 'success'));
      dispatch({
        type: actionTypes.RESET_PASSWORD_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch(addNotify('Error', 'error'));
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    }
  };
}

function changeUserBalance(id, amount, isOutcome, callback) {
  return async (dispatch, getState, extra) => {
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
    const { api } = extra;
    const { locale } = getState().locale;
    const response = await api.user.changeUserBalance(id, amount, isOutcome);
    if (response.success) {
      dispatch(getUsersList());
      callback('');
      dispatch(addNotify(isOutcome ? locale.withdrawalSuccess : locale.topUpSuccess, 'success'));
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    } else {
      dispatch(addNotify('Error', 'error'));
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    }
  };
}

function depositOrWithdrawal({ amount, operation, id, callback }) {
  return async (dispatch, getState, extra) => {
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
    const { api } = extra;
    const { locale } = getState().locale;
    const requestBody = {
      amount,
      operation, // 0 - deposit, 1 - withdrawal
      id,
    };

    if (requestBody.amount.length < 1) {
      dispatch(addNotify('amount shoud not be empty', 'error'));
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    } else {
      const response = await api.user.depositOrWithdrawal(requestBody);
      if (response.success) {
        dispatch(addNotify(locale.createSuccess, 'success'));
        dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
        callback && callback();
      } else {
        dispatch(addNotify(response.errorMessage, 'error'));
        dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
      }
    }
  };
}

function changePassword({ data, id, callback }) {
  return async (dispatch, getState, extra) => {
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
    const { api } = extra;
    const { locale } = getState().locale;

    if (data.password.length < 1) {
      dispatch(addNotify('password shoud not be empty', 'error'));
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    } else {
      const response = await api.user.changeUserInfo({ data, id });
      if (response.success) {
        dispatch(addNotify(locale.createSuccess, 'success'));
        dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
        dispatch(actions.checkAuth());
        callback && callback();
      } else {
        dispatch(addNotify(response.errorMessage, 'error'));
        dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
      }
    }
  };
}

function disableUser({ data, id, callback }) {
  return async (dispatch, getState, extra) => {
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
    const { api } = extra;
    const { locale } = getState().locale;

    const response = await api.user.changeUserInfo({ data, id });
    if (response.success) {
      dispatch(addNotify(locale.createSuccess, 'success'));
      dispatch({ type: actionTypes.DATA_REFRESH, payload: true });
      callback && callback();
    } else {
      dispatch(addNotify(response.errorMessage, 'error'));
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    }
  };
}

function modifyAgent({ data, id, callback }) {
  return async (dispatch, getState, extra) => {
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
    const { api } = extra;
    const { locale } = getState().locale;

    const requestBody = {
      first_name: data.name,
      last_name: data.surname,
      email: data.email,
      is_withdrawal_access: data.withdrawalAllowed,
      is_deposit_access: data.depositAllowed,
    };


      const response = await api.user.changeUserInfo({ data: requestBody, id });
      if (response.success) {
        dispatch(addNotify(locale.createSuccess, 'success'));
        dispatch({ type: actionTypes.DATA_REFRESH, payload: true });
        callback && callback();
      } else {
        dispatch(addNotify(response.errorMessage, 'error'));
        dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
      }

  };
}

function getUserInfo({ id, callback, withParent = false }) {
  return async (dispatch, getState, extra) => {
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
    const { api } = extra;

    const response = await api.user.getUserInfo({ id });
    if (response.success) {
      const parentAgentId = response.data?.parentAgentId;

      if (withParent && parentAgentId) {
        const responseParent = await api.user.getUserInfo({ id: parentAgentId });
        if (responseParent.success) {
          response.data.parentInfo = responseParent.data;
        }
      }

      dispatch({
        type: actionTypes.GET_USER_INFO_SUCCESS,
        payload: response.data,
      });
      callback && callback();
    } else {
      dispatch(addNotify(response.errorMessage, 'error'));
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    }
  };
}

function deleteUserInfo() {
  return async (dispatch, getState, extra) => {
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
    dispatch({
      type: actionTypes.USER_INFO_DELETE,
    });
  };
}

export {
  actionTypes,
  createUser,
  getUsersList,
  changeBanState,
  resetPassword,
  changeUserBalance,
  createAgent,
  depositOrWithdrawal,
  changePassword,
  disableUser,
  getUserInfo,
  deleteUserInfo,
  modifyAgent,
};
