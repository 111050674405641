import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import block from 'bem-cn';

import Button from 'components/Button';
import Spinner from 'components/Spinner/view/desktop';
import { actions as authActions } from 'features/Auth';
import SVG from 'components/SVG';
import { userTypes } from '../../../../data';
import Input from 'components/Input';
import { actions } from 'features/users/redux';

import './Deposit.scss';

const b = block('deposit');

const Deposit = () => {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const {
    username: authUsername,
    balance: authBalance,
    role: authRole,
    currency,
  } = useSelector(state => state.auth);
  const history = useHistory();
  const locale = useSelector(state => state.locale.locale);
  const { userInfo, actionProcessing } = useSelector(state => state.users);
  const [amount, setAmount] = useState();

  useEffect(() => {
    dispatch(actions.getUserInfo({ id: userId, withParent: true }));
  }, []);

  const players = useMemo(
    () => [
      {
        username: userInfo?.parentInfo?.username ?? authUsername,
        balance: userInfo?.parentInfo?.balance ?? authBalance,
        role: userInfo?.parentInfo?.role ?? authRole,
        title: locale.from
      },
      {
        username: userInfo.username,
        balance: userInfo.balance,
        role: userInfo.role,
        title: locale.to
      },
    ],
    [authBalance, authUsername, authRole, userInfo, locale],
  );

  const onSubmit = useCallback(() => {
    dispatch(
      actions.depositOrWithdrawal({
        amount,
        operation: 0,
        id: userId,
        callback: () => {
          dispatch(actions.getUserInfo({ id: userId }));
          dispatch(authActions.getBalance());
          history.push('/users/all');
        },
      }),
    );
  }, [amount, dispatch, userId]);

  const changeValue = useCallback(e => {
    setAmount(e.currentTarget.value);
  }, []);

  if (actionProcessing) return <Spinner isLoading={actionProcessing} />;

  return (
    <div className={b()}>
      <div className={b('wrapper')}>
        <div className={b('top')}>
          <div className={b('header')}>{locale.deposit}</div>
          <div className={b('header', { small: true })}>{locale.transferOfFunds}</div>
          <div className={b('user-blocks')}>
            {players.map(player => (
              <div className={b('user-block')}>
                <div className={b('user-block-title')}>{player.title}</div>
                <div className={b('user-block-wrapper')}>
                  <div className={b('user-block-left')}>
                    <SVG svgProps={{ svg: userTypes[player.role].icon }} className={b('user-block-icon')} />
                    <div className={b('user-block-info')}>
                      <span>{player.username}</span>
                      <div className={b('user-block-info-type', { role: player.role })}>
                        {userTypes[player.role].type}
                      </div>
                    </div>
                  </div>
                  <div className={b('user-block-right')}>
                    <span>{locale.balance}:</span>
                    <span>{player.balance}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className={b('input-block')}>
            <span className={b('input-block-title')}>{locale.amount}</span>
            <div className={b('input')}>
              <Input
                placeholder={locale.amount}
                withEye
                validState={amount > 0 ? 1 : 3}
                type="number"
                onChange={changeValue}
                value={amount}
                name="amount"
              />
              <span className={b('input-currency')}>{currency}</span>
            </div>
          </div>
        </div>
        <div className={b('bottom')}>
          <Button color="full-transparent" callBack={history.goBack} text={locale.cancel} size="low" />
          <Button callBack={onSubmit} type="submit" size="low" text={locale.deposit} />
        </div>
      </div>
    </div>
  );
};

export default Deposit;
