import React from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';

import SVG from 'components/SVG';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import { getActionItems, userTypes } from '../../../data';

import './TableRow.scss';

const TableRow = ({ item, isWithdrawalAccess, isDepositAccess, locale }) => {
  const b = block('adm-bets-table-row-user');

  return (
    <div className={b({ banned: item.isBanned })}>
      <div className={b('td')}>
        <div className={b('td-data')}>
          <SVG svgProps={{ svg: userTypes[item.role].icon }} className={b('td-data-icon')} />
          <div className={b('td-data-user')}>
            <span>{item.username}</span>
            <div className={b('td-data-user-type', { role: item.role })}>{userTypes[item.role].type}</div>
          </div>
        </div>
      </div>
      <div className={b('td')}>{item.balance}</div>
      <div className={b('td')}>
        <div className={b('td-buttons')}>
          {isDepositAccess && <Button disabled={item.isBanned} link={`/user/deposit/${item.id}`} text={locale.topup} />}
          {isWithdrawalAccess && (
            <Button
              disabled={item.isBanned}
              link={`/user/withdrawal/${item.id}`}
              color="full-transparent"
              text={locale.withdrawal}
            />
          )}
        </div>
      </div>
      <div className={b('td')}>
        {+item.role !== 3 && (
          <div className={b('td-actions')}>
            <Dropdown
              user={item}
              items={getActionItems({ role: item.role, isBanned: item.isBanned })}
              locale={locale}
            />
          </div>
        )}
      </div>
    </div>
  );
};

TableRow.propTypes = {
  item: PropTypes.object.isRequired,
};

export default TableRow;
