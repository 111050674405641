import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router';
import dayjs from 'dayjs';

import { actions } from 'features/users/redux';
import { actions as treeActions } from 'features/tree';

import { balanceSortTypes } from "../data";

const useUsersModel = () => {
  const { tab } = useParams();
  const { id } = useSelector(state => state.auth, shallowEqual);
  const { isNeedToRefresh } = useSelector(state => state.users, shallowEqual);
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const { selectedAgentId, agentClickedTrigger } = useSelector(state => state.tree, shallowEqual);
  const dispatch = useDispatch();
  const firstRender = useRef(true);

  const [filterValue, setFilterValue] = useState('');
  const [count, setCount] = useState(10);
  const [actionProcessing, changeActionProcessing] = useState(false);
  const [page, setPage] = useState(0);
  const [itemsOnPage, setItemsOnPage] = useState({ value: 10, name: '10' });
  const [date, setDate] = useState({
    beginDate: null,
    endDate: null,
  });

  const [balanceSortType, setBalanceSortType] = useState({
    ...balanceSortTypes[2],
    name: `(${locale[balanceSortTypes[2].name]})`,
  });

  const role = useMemo(() => {
    if (tab === 'players') {
      setPage(0);
      return 0;
    }
    if (tab === 'agents') {
      setPage(0);
      return 6;
    }
    if (tab === 'all' || tab === 'disabled') {
      setPage(0);
      return '';
    }
  }, [tab]);

  const isBanned = useMemo(() => {
    if (tab === 'disabled') {
      return true;
    } else return false;
  }, [tab]);

  const selectItemChangedHandler = itemValue => {
    setItemsOnPage({ value: itemValue, name: itemValue });
    onItemsOnPageChanged(itemValue);
  };

  const onSetBalanceSort = type => {
    const match = balanceSortTypes.find(el => el.value === type);
    setBalanceSortType({ ...match, name: `(${locale[match.name]})` });
  }


  const onChangeFilterValue = e => {
    setFilterValue(e.currentTarget.value?.toLowerCase());
  }

  const onItemsOnPageChanged = newItemsOnPage => {
    setCount(newItemsOnPage);
    setPage(0);
  };

  const onChangeBeginDate = value => {
    if (!isNaN(value.valueOf())) {
      setDate({ ...date, beginDate: value });
    }
  };

  const onChangeEndDate = value => {
    if (!isNaN(value.valueOf())) {
      setDate({ ...date, endDate: value });
    }
  };

  const onResetFilter = () => {
    setFilterValue('');
    setDate({
      beginDate: null,
      endDate: null,
    });
    getUserWithFilter({
      username: '',
      date_from: '',
      date_to: '',
    });
  };

  const getUserWithFilter = (data = {}, withName) =>
    dispatch(actions.getUsersList({
      username: filterValue,
      role,
      userId: selectedAgentId || id,
      isBanned,
      count,
      page,
      order_by: balanceSortType.value === 0 ? null : balanceSortType.value,
      date_from: date.beginDate ? dayjs(date.beginDate).format('YYYY-MM-DD') : '',
      date_to: date.endDate ? dayjs(date.endDate).add(1, 'day').format('YYYY-MM-DD') : '',
      ...data,
    }, withName))


  const getTreeByUsername = () => {
    changeActionProcessing(true);
    getUserWithFilter({
      userId: !filterValue ? (selectedAgentId ?? id) : undefined
    }, true)
      .then(user => {
        if (user?.id) {
          dispatch(treeActions.getUsersByUserId({ id: user.id, nesting: [user.id], name: user.username }, true)).finally(() => {
            dispatch(treeActions.selectAgentId(user.id));
            if (filterValue) {
              setFilterValue('');
              getUserWithFilter({ username: '', userId: user.id });
            }

            changeActionProcessing(false);
          });
        } else {
          changeActionProcessing(false);
        }
      })
  };

  useEffect(() => {
    if (!firstRender.current) {
      onResetFilter();
    }

    firstRender.current = false;
  }, [agentClickedTrigger]);

  useEffect(() => {
    getTreeByUsername();
  }, [role, isBanned, id, page, itemsOnPage.value, isNeedToRefresh, balanceSortType]);

  return ({
    filterValue,
    page,
    date,
    itemsOnPage,
    balanceSortType,
    role,
    actionProcessing,
    isBanned,
    onChangeFilterValue,
    onChangeBeginDate,
    onChangeEndDate,
    onResetFilter,
    onSetBalanceSort,
    onItemsOnPageChanged,
    setPage,
    setBalanceSortType,
    selectItemChangedHandler,
    getTreeByUsername,
    getUserWithFilter,
  })
}

export default useUsersModel;
