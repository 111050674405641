import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import block from 'bem-cn';
import { useSelector } from 'react-redux';
import Spinner from 'components/Spinner/view/desktop';
import { actions } from 'features/users/redux';
import { useHistory, useParams } from 'react-router-dom';
import { userTypes } from '../../../../data';
import SVG from 'components/SVG';
import Button from 'components/Button';

import './Information.scss';

const b = block('information');

const Information = () => {
  const { userInfo, actionProcessing } = useSelector(state => state.users);
  const dispatch = useDispatch();
  const { userId } = useParams();
  const locale = useSelector(state => state.locale.locale);
  const history = useHistory();

  useEffect(() => {
    dispatch(actions.getUserInfo({ id: userId }));
  }, []);

  if (actionProcessing) return <Spinner isLoading={actionProcessing} />;

  return (
    <div className={b()}>
      <div className={b('wrapper')}>
        <div className={b('top')}>
          <div className={b('header')}>{locale.information}</div>
          <div className={b('user-block')}>
            <div className={b('user-block-left')}>
              <SVG svgProps={{ svg: userTypes[userInfo.role].icon }} className={b('user-block-icon')} />
              <div className={b('user-block-info')}>
                <span>{userInfo.username}</span>
                <div className={b('user-block-info-type', { role: userInfo.role })}>
                  {userTypes[userInfo.role].type}
                </div>
              </div>
            </div>
            <div className={b('user-block-right')}>
              <span>ID:</span>
              <span>{userId}</span>
            </div>
            <div className={b('user-block-right')}>
              <span>{locale.balance}:</span>
              <span>{userInfo.balance}</span>
            </div>
          </div>
          <div className={b('block-wrapper')}>
            <div className={b('block')}>
              <div className={b('block-title')}>{locale.creationDate}</div>
              <div className={b('block-value')}>{userInfo.firstVisit}</div>
            </div>
            <div className={b('block')}>
              <div className={b('block-title')}>{locale.modificationDate}</div>
              <div className={b('block-value')}>{userInfo.lastVisit}</div>
            </div>
          </div>
          <div className={b('block-wrapper')}>
            <div className={b('block')}>
              <div className={b('block-title')}>{locale.username}</div>
              <div className={b('block-value')}>{userInfo.username}</div>
            </div>

            <div className={b('block')}>
              <div className={b('block-title')}>{locale.name}</div>
              <div className={b('block-value')}>{userInfo.name}</div>
            </div>

            <div className={b('block')}>
              <div className={b('block-title')}>{locale.email}</div>
              <div className={b('block-value')}>{userInfo.email}</div>
            </div>

            <div className={b('block')}>
              <div className={b('block-title')}>{locale.surname}</div>
              <div className={b('block-value')}>{userInfo.surname}</div>
            </div>

            <div className={b('block')}>
              <div className={b('block-title')}>{locale.currency}</div>
              <div className={b('block-value')}>{userInfo.currency}</div>
            </div>
          </div>
        </div>
        <div className={b('bottom')}>
          <Button color="full-transparent" callBack={history.goBack} text={locale.close} size="low" />
        </div>
      </div>
    </div>
  );
};

export default Information;
