import dayjs from "dayjs";

export const providers = [
  'pragmatic',
  'netent',
  'playngo',
  'evoplay',
  'gameart',
  'platipus',
  'betsoft',
  'amatic',
  'wazdan',
  'playson',
  'quickspin',
  'bsw',
  'playtech',
  'dlv',
  'fishing',
  'endorphina',
  'merkur',
  'kajot',
  'wacs',
  'gaminator',
  'mrslotty',
  'igt',
  'greentube',
  'aristocrat',
  'apollo',
  'inbet',
  'konami',
  'austria',
  'flg',
  'cqgaming',
  'relaxgaming',
  'booongo',
  'igrosoft',
  'nolimit',
  'wmg',
  'elbet',
  'redrake',
  'spribe',
  'pragmaticlive',
  'evolutionlive',
  'pragmaticlive',
  'tvbet',
  'xpg',
  'goldenrace',
];

export const daysFilterValue = [
  {
    text: 'today',
    value: 0,
  },
  {
    text: 'yesterday',
    value: -1,
  },
  {
    text: 'lastWeek',
    value: -7,
  },
  {
    text: 'lastMonth',
    value: -(dayjs().add(-1, 'month').daysInMonth() - 1),
  },
];

export const daysFilterValueMobile = [
  {
    text: 'today',
    value: 0,
  },
  {
    text: 'yesterday',
    value: -1,
  },
  {
    text: 'lastWeek',
    value: -7,
  },
];

export const structure = [
  {
    name: 'allUsers',
    value: 'allUsers',
  },
  {
    name: 'directOnly',
    value: 'directOnly',
  },
];

export const searchInItems = [
  {
    name: 'agent',
    value: '6',
  },
  {
    name: 'user',
    value: '0',
  },
];

export const gameTypeValues = {
  ALL: 'all',
  SLOTS: 'slots',
  LIVE_CASINO: 'live-casino',
  VIRTUAL_GAMES: 'virtual-games',
}

export const gameTypes = [
  {
    name: 'all',
    value: gameTypeValues.ALL,
  },
  {
    name: 'slots',
    value: gameTypeValues.SLOTS,
  },
  {
    name: 'liveCasino',
    value: gameTypeValues.LIVE_CASINO,
  },
  {
    name: 'virtualGames',
    value: gameTypeValues.VIRTUAL_GAMES,
  },
  // {
  //   name: 'fiable',
  //   value: 'fiable',
  // },
];

export const providerItems = [
  {
    name: 'all',
    value: '',
    types: [],
  },
  {
    name: 'INBET',
    value: 'INBET',
    types: [gameTypeValues.SLOTS],
  },
  {
    name: 'OUTCOME',
    value: 'OUTCOME',
    types: [gameTypeValues.SLOTS],
  },
  {
    name: 'PRAGMATIC',
    value: 'PRAGMATIC',
    types: [gameTypeValues.SLOTS],
  },
  {
    name: 'GOLDENRACE',
    value: 'GOLDENRACE',
    types: [gameTypeValues.VIRTUAL_GAMES],
  },
  {
    name: 'TVBET',
    value: 'TVBET',
    types: [gameTypeValues.LIVE_CASINO],
  },
  {
    name: 'XPG',
    value: 'XPG',
    types: [gameTypeValues.LIVE_CASINO],
  },

  // {
  //   name: 'EVOPLAY',
  //   value: 'EVOPLAY',
  // },
  // {
  //   name: 'EVOLUTION',
  //   value: 'EVOLUTION',
  // },
  // {
  //   name: 'FIABLE',
  //   value: 'FIABLE',
  // },
  // {
  //   name: 'BSWGAMES',
  //   value: 'BSWGAMES',
  // },
  // {
  //   name: 'SOFTGAMING',
  //   value: 'SOFTGAMING',
  // },
  // {
  //   name: 'SLOTEGRATOR',
  //   value: 'SLOTEGRATOR',
  // },
  // {
  //   name: 'BETRADAR',
  //   value: 'BETRADAR',
  // },
  // {
  //   name: 'LINE1X',
  //   value: 'LINE1X',
  // },
  // {
  //   name: 'FLG',
  //   value: 'FLG',
  // },
];

export const statusBets = [
  {
    name: 'win',
    value: 1,
  },
  {
    name: 'bet',
    value: 2,
  },
  {
    name: 'refund',
    value: 3,
  },
];

export const statusSportBets = [
  {
    name: 'wait',
    value: 2,
  },
  {
    name: 'win',
    value: 1,
  },
  {
    name: 'return',
    value: 3,
  },
  // {
  //   name: 'loss',
  //   value: 4,
  // },
  // {
  //   name: 'sold',
  //   value: 5,
  // },
];
