import React from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';

import './Table.scss';

const b = block('reports-table-general');

const Table = ({ thItems, trItems }) => {
  const locale = useSelector(state => state.locale.locale);
  const { currency } = useSelector(state => state.auth);

  return (
    <div className={b()}>
      <div className={b('header')}>{locale.total}</div>
      <div className={b('table')}>
        {thItems.map((el, idx) => (
          <div className={b('table-tr')}>
            <div className={b('table-th')}>{locale[el]}</div>
            <div className={b('table-td')}>
              <div className={b('table-td-amount')}>{trItems[0][idx]}</div>
              {idx > 0 && <div className={b('table-td-currency')}>{currency}</div>}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

Table.propTypes = {};

export default Table;
