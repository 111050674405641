import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

/**
 * Преобразует входящую дату/время из UTC в GMT-3
 *
 * @param {string} time - Входящая дата/время в строковом формате.
 *
 */
export const getTimeFromUTCToTimezone = date => {
  const dateObj = dayjs(date);

  if (!date || !dateObj?.isValid()) {
    return null;
  }

  return dateObj?.subtract(3, 'hour');
};
