import React, { useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import block from 'bem-cn';

import Spinner from 'components/Spinner/view/desktop';

import FilterBlock from './FilterBlock';
import Table from 'components/TableWithPagination/desktop/Table/Table';

import './PlayerHistory.scss';

const b = block('player-history');

const PlayerHistory = () => {
  const { actionProcessing } = useSelector(state => state.reports, shallowEqual);
  const slotsHistory = useSelector(state => state.reports.slotsHistory, shallowEqual);
  const locale = useSelector(state => state.locale.locale, shallowEqual);

  const [count, setCount] = useState(10);
  const [page, setPage] = useState(0);

  const onItemsOnPageChanged = newItemsOnPage => {
    setCount(newItemsOnPage);
    setPage(0);
  };

  return (
    <div className={b()}>
      <Spinner isLoading={actionProcessing} />
      <FilterBlock count={count} currentPage={page} onPageClick={setPage} />
      <Table
        currentPage={page}
        onPageClick={setPage}
        onItemsOnPageChanged={onItemsOnPageChanged}
        thTdItems={slotsHistory.data}
        totalCount={slotsHistory.totalCount}
        type="player-history"
      />
    </div>
  );
};

export default PlayerHistory;
