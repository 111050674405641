import dayjs from 'dayjs';

export class UserApiConverter {
  convertUsersList = (data, additionalData) => {
    const { source_user, users } = data;
    const parent = {
      ...source_user,
      parent_agent: {
        balance: source_user.balance,
        id: source_user.id,
        username: source_user.username,
      },
    };
    const fullUsers = [{ ...parent }, ...users].filter(el =>
      Number.isInteger(additionalData.role) ? Number(el.role) === additionalData.role : true,
    );
    const userList = fullUsers.map(el => ({
      balance: el.balance,
      id: el.id,
      isBanned: el.is_banned,
      role: el.role,
      username: el.username,
      parentAgent: {
        balance: el.parent_agent.balance,
        id: el.parent_agent.id,
        username: el.parent_agent.username,
      },
    }));

    return {
      users: userList,
      totalCount: data.total_count || 0,
      balanceSum: data.balance_sum || 0,
    };
  };

  convertNewPassword = data => data.new_password;

  convertUserInfo = data => ({
    id: data.user.id,
    name: data.user.first_name,
    surname: data.user.last_name,
    username: data.user.username,
    email: data.user.email,
    role: data.user.role,
    balance: data.user.balance,
    bonusBalance: data.user.bonus_balance,
    currency: data.user.currency,
    parentAgentId: data.user.parent_agent_id,
    structurePath: data.user.structure_path,
    note: data.user.note,
    isBanned: data.user.is_banned,
    isVisible: data.user.is_visible,
    isDepositAccess: data.user?.is_deposit_access ?? true,
    isWithdrawalAccess: data.user.is_withdrawal_access,
    firstVisit: data.user.first_visit.length === 0 ? '' : dayjs(data.user.first_visit).format('DD/MM/YYYY'),
    lastVisit: data.user.last_visit.length === 0 ? '' : dayjs(data.user.last_visit).format('DD/MM/YYYY'),
    structure: data.structure,
  });
}
