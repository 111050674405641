import React from 'react';
import block from 'bem-cn';
import { useSelector, shallowEqual } from 'react-redux';
import Select from 'components/Select/desktop';

import useUsersModel from '../../model/useUsersModel';
import UsersFilterBlock from './UsersFilterBlock';

import Table from './Table/Table';
import Tabs from './Tabs/Tabs';

import './Users.scss';

const b = block('users');

const Users = () => {
  // const { actionProcessing } = useSelector(state => state.users, shallowEqual);
  const locale = useSelector(state => state.locale.locale, shallowEqual);

  const model = useUsersModel();

  const itemsOnPageFilters = [
    { value: 10, name: '10' },
    { value: 25, name: '25' },
    { value: 50, name: '50' },
    { value: 100, name: '100' },
  ];

  return (
    <div className={b()}>
      <div className={b('center')}>
        <Tabs />
        <div className={b('center-controller')}>
          <UsersFilterBlock
            callBack={model.getTreeByUsername}
            actionProcessing={model.actionProcessing}
            filterValue={model.filterValue}
            changeFilterValue={model.onChangeFilterValue}
          />
          <div className={b('page-count')}>
            <div className={b('page-count-title')}>{locale.recordsOnPage}:</div>
            <div className={b('page-count-select')}>
              <Select
                paginator
                items={itemsOnPageFilters}
                activeItem={model.itemsOnPage}
                onChange={model.selectItemChangedHandler}
              />
            </div>
          </div>
        </div>
      </div>
      <Table
        filterValue={model.filterValue}
        locale={locale}
        currentPage={model.page}
        onPageClick={model.setPage}
        itemsOnPage={model.itemsOnPage}
        balanceSortType={model.balanceSortType}
        onSetBalanceSort={model.onSetBalanceSort}
        role={model.role}
      />
    </div>
  );
};

export default Users;
