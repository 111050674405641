export const convertFieldsToSearch = fields => {
  if (!fields) return '';
  const keys = Object.keys(fields);

  const params = keys
      .map(field => fields[field] !== null && !!`${fields[field]}`.length && `${field}=${fields[field]}`)
      .filter(el => el)
      .join('&');
  return '?' + params;
};

