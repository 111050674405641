import changePasswordSVG from '../img/change-password.svg';
import disableSVG from '../img/disable.svg';
import editSVG from '../img/edit.svg';
import informationsSVG from '../img/informations.svg';
import agentSVG from '../img/agent.svg';
import playerSVG from '../img/player.svg';
import moreToLessSVG from '../img/moreToLess.svg';
import lessToMoreSVG from '../img/lessToMore.svg';

export const balanceSortTypes = [
  {
    value: '-balance',
    name: 'moreToLess',
    svg: moreToLessSVG,
  },
  {
    value: '+balance',
    name: 'lessToMore',
    svg: lessToMoreSVG,
  },
  {
    value: 0,
    name: 'none',
  },
];

export const userTypes = {
  0: {
    icon: playerSVG,
    type: 'player',
  },
  6: {
    icon: agentSVG,
    type: 'agent',
  },
};

export const actionItems = {
  forEnabled: [
    {
      svg: informationsSVG,
      title: 'informations',
      link: '/user/info',
    },
    {
      svg: editSVG,
      title: 'edit',
      link: '/user/edit',
    },
    {
      svg: changePasswordSVG,
      title: 'changePassword',
      link: '/user/change-password',
    },
    {
      svg: disableSVG,
      title: 'disable',
      link: '/user/disable',
    },
  ],
  forDisabled: [
    {
      svg: disableSVG,
      title: 'enable',
      link: '/user/enable',
    },
  ],
};

export const getActionItems = ({ role, isBanned }) => {
  if (isBanned) {
    return [
      {
        svg: disableSVG,
        title: 'enable',
        link: '/user/enable',
      },
    ];
  }
  if (+role === 0) {
    return [
      {
        svg: informationsSVG,
        title: 'informations',
        link: '/user/info',
      },
      {
        svg: editSVG,
        title: 'edit',
        link: '/user/edit',
      },
      {
        svg: changePasswordSVG,
        title: 'changePassword',
        link: '/user/change-password',
      },
      {
        svg: disableSVG,
        title: 'disable',
        link: '/user/disable',
      },
    ];
  }
  if (+role === 6) {
    return [
      {
        svg: informationsSVG,
        title: 'informations',
        link: '/user/info',
      },
      {
        svg: editSVG,
        title: 'edit',
        link: '/user/edit',
      },
      {
        svg: changePasswordSVG,
        title: 'changePassword',
        link: '/user/change-password',
      },
      {
        svg: disableSVG,
        title: 'disable',
        link: '/user/disable',
      },
    ];
  }
};
