import React, { useState, useCallback, useContext } from 'react';
import block from 'bem-cn';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ModalContext } from 'components/ModalProvider/ModalProvider';

import Button from 'components/Button';
import Input from 'components/Input';
import { actions } from 'features/users/redux';

import './CreatePlayer.scss';

const b = block('create-player');

const CreatePlayer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const { openModal, closeModal } = useContext(ModalContext);

  const [form, setForm] = useState({
    username: '',
    password: '',
    confirmPassword: '',
    email: '',
    name: '',
    surname: '',
  });

  const onSubmit = useCallback(() => {
    closeModal();
    dispatch(actions.createUser({ form, callback: () => history.push('/users/all') }));
  }, [closeModal, dispatch, form, history]);

  const openPopUp = useCallback(
    e => {
      e.preventDefault();
      openModal({
        content: (
          <div className={b('confirmation-pop-up')}>
            <div
              className={b('confirmation-pop-up', 'description')}
              dangerouslySetInnerHTML={{
                __html: `${locale.wantToCreateAgent} <span> ${form.username}</span>?`,
              }}
            />
            <div className={b('confirmation-pop-up', 'buttons')}>
              <Button color="transparent" size="low" callBack={onSubmit} text={locale.createPlayer} />
              <Button callBack={closeModal} text={locale.cancel} size="low" />
            </div>
          </div>
        ),
      });
    },
    [openModal, locale.wantToCreateAgent, locale.createPlayer, locale.cancel, form.username, onSubmit, closeModal],
  );

  const changeValue = useCallback(
    e => {
      setForm({ ...form, [e.currentTarget.name]: e.currentTarget.value?.trim() });
    },
    [form],
  );

  const changeValueUsername = useCallback(
    e => {
      setForm({ ...form, username: e.currentTarget.value?.trim() });
    },
    [form],
  );

  const disabled = form.confirmPassword !== form.password || form.username.length === 0 || form.password.length === 0;

  return (
    <div className={b()}>
      <form className={b('form')} onSubmit={openPopUp}>
        <div className={b('header')}>{locale.createPlayer}</div>
        <div className={b('fields')}>
          <div className={b('form', 'input-block')}>
            <div className={b('input')}>
              <span className={b('form', 'input-block-title')}>
                {locale.username}
                <span className={b('star')}>{` *`}</span>
              </span>
              <Input
                validState={form.username.length > 0 ? 1 : 3}
                placeholder={locale.username}
                onChange={changeValueUsername}
                value={form.username}
              />
            </div>
          </div>
          <div className={b('form', 'input-block')}>
            <div className={b('input')}>
              <span className={b('form', 'input-block-title')}>{locale.email}</span>
              <Input placeholder={locale.email} onChange={changeValue} value={form.email} name="email" />
            </div>
          </div>
          <div className={b('form', 'input-block')}>
            <span className={b('form', 'input-block-title')}>
              {locale.password}
              <span className={b('star')}>{` *`}</span>
            </span>
            <div className={b('input')}>
              <Input
                placeholder={locale.password}
                withEye
                validState={form.password.length > 0 ? 1 : 3}
                type="password"
                onChange={changeValue}
                value={form.password}
                name="password"
              />
            </div>
          </div>
          <div className={b('form', 'input-block')}>
            <span className={b('form', 'input-block-title')}>{locale.name}</span>
            <div className={b('input')}>
              <Input placeholder={locale.name} onChange={changeValue} value={form.name} name="name" />
            </div>
          </div>
          <div className={b('form', 'input-block')}>
            <span className={b('form', 'input-block-title')}>
              {locale.confirmPassword}
              <span className={b('star')}>{` *`}</span>
            </span>
            <div className={b('input')}>
              <Input
                placeholder={locale.confirmPassword}
                onChange={changeValue}
                value={form.confirmPassword}
                name="confirmPassword"
                withEye
                type="password"
                validState={form.confirmPassword !== form.password ? 3 : 1}
              />
            </div>
          </div>
          <div className={b('form', 'input-block')}>
            <span className={b('form', 'input-block-title')}>{locale.surname}</span>
            <div className={b('input')}>
              <Input placeholder={locale.surname} onChange={changeValue} value={form.surname} name="surname" />
            </div>
          </div>
        </div>

        <div className={b('bottom')}>
          <Button color="full-transparent" callBack={history.goBack} text={locale.cancel} size="low" />
          <Button disabled={disabled} type="submit" size="low" text={locale.createPlayer} />
        </div>
      </form>
    </div>
  );
};

export default CreatePlayer;
