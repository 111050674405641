import React, { useState } from 'react';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LanguageDropdownSelector from 'components/LanguageDropdownSelector';
import HeaderDropdown from './HeaderDropdown';

import userSVG from '../img/user.svg';
import arrowSVG from '../img/arrow.svg';

import './Header.scss';

const Header = () => {
  const b = block('header');
  const [headerDropdownVisible, setHeaderDropdownVisible] = useState(false);
  const auth = useSelector(state => state.auth);
  const locale = useSelector(state => state.locale.locale);

  return (
    <div className={b({ open: headerDropdownVisible })}>
      <div className={b('language-selector')}>
        <LanguageDropdownSelector isHeader />
      </div>
      {/*<div className={b('user-balance')}>*/}
      {/*  <div className={b('user-balance-info')}>*/}
      {/*    <span className={b('user-balance-title')}>{locale.balance}</span>*/}
      {/*    <span className={b('user-info-login')}>{`${auth.balance}`}</span>*/}
      {/*  </div>*/}
      {/*</div>*/}
        <div onClick={() => setHeaderDropdownVisible(!headerDropdownVisible)} className={b('user')}>
        <div className={b('user-info')}>
          <span className={b('user-info-id')}>{`${locale.id}: ${auth.id}`}</span>
          <span className={b('user-info-login')}>{`${auth.username}`}</span>
        </div>
        <SVGInline svg={userSVG} className={b('user-img').toString()} />
        <SVGInline svg={arrowSVG} className={b('user-arrow').toString()} />
      </div>
      {headerDropdownVisible && <HeaderDropdown changeOpen={setHeaderDropdownVisible} />}
    </div>
  );
};

export default withRouter(Header);
