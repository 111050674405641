import React, { useState, useCallback, useEffect } from 'react';
import block from 'bem-cn';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Button from 'components/Button';
import Input from 'components/Input';
import SVG from 'components/SVG';
import Spinner from 'components/Spinner/view/desktop';
import Switcher from 'components/Switcher';
import { actions } from 'features/users/redux';
import { userTypes } from '../../../../data';

import './ModifyAgent.scss';

const b = block('modify-agent');

const ModifyAgent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { userId } = useParams();
  const locale = useSelector(state => state.locale.locale);

  const { userInfo, actionProcessing } = useSelector(state => state.users);

  useEffect(() => {
    dispatch(actions.getUserInfo({ id: userId }));
  }, []);

  const [form, setForm] = useState({
    username: '',
    email: '',
    name: '',
    surname: '',
  });

  useEffect(() => {
    setForm({
      email: userInfo.email,
      name: userInfo.name,
      surname: userInfo.surname,
      username: userInfo.username,
    });
    setWithdrawalAllowed(userInfo.isWithdrawalAccess);
    setDepositAllowed(userInfo.isDepositAccess);
  }, [userInfo]);

  const [withdrawalAllowed, setWithdrawalAllowed] = useState(false);
  const [depositAllowed, setDepositAllowed] = useState(false);

  const changeValue = useCallback(
    e => {
      setForm({ ...form, [e.currentTarget.name]: e.currentTarget.value });
    },
    [form],
  );

  const isAgent = +userInfo.role === 6;

  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      dispatch(
        actions.modifyAgent({
          data: { ...form, withdrawalAllowed, depositAllowed },
          id: userId,
          callback: () => history.push('/users/all'),
        }),
      );
    },
    [form, dispatch, withdrawalAllowed, userId, depositAllowed],
  );

  const disabled = form.username.length === 0;

  if (actionProcessing) return <Spinner isLoading={actionProcessing} />;

  return (
    <div className={b()}>
      <form className={b('form')} onSubmit={onSubmit}>
        <div className={b('header')}>{locale.userEdit}</div>
        <div className={b('user-block')}>
          <div className={b('user-block-left')}>
            <SVG svgProps={{ svg: userTypes[userInfo.role].icon }} className={b('user-block-icon')} />
            <div className={b('user-block-info')}>
              <span>{userInfo.username}</span>
              <div className={b('user-block-info-type', { role: userInfo.role })}>{userTypes[userInfo.role].type}</div>
            </div>
          </div>
          <div className={b('user-block-right')}>
            <span>ID:</span>
            <span>{userId}</span>
          </div>
          <div className={b('user-block-right')}>
            <span>{locale.balance}:</span>
            <span>{userInfo.balance}</span>
          </div>
        </div>
        <div className={b('fields')}>
          <div className={b('form', 'input-block')}>
            <div className={b('input')}>
              <span className={b('form', 'input-block-title')}>{locale.username}</span>
              <Input
                validState={form.username.length > 0 ? 1 : 3}
                placeholder={locale.username}
                onChange={changeValue}
                value={form.username}
                name="username"
              />
            </div>
          </div>
          <div className={b('form', 'input-block')}>
            <span className={b('form', 'input-block-title')}>{locale.name}</span>
            <div className={b('input')}>
              <Input placeholder={locale.name} onChange={changeValue} value={form.name} name="name" />
            </div>
          </div>
          <div className={b('form', 'input-block')}>
            <div className={b('input')}>
              <span className={b('form', 'input-block-title')}>{locale.email}</span>
              <Input placeholder={locale.email} onChange={changeValue} value={form.email} name="email" />
            </div>
          </div>
          <div className={b('form', 'input-block')}>
            <span className={b('form', 'input-block-title')}>{locale.surname}</span>
            <div className={b('input')}>
              <Input placeholder={locale.surname} onChange={changeValue} value={form.surname} name="surname" />
            </div>
          </div>
        </div>

        {isAgent && (
          <div className={b('form', 'switchers')}>
            <div className={b('form', 'switchers-block')}>
              <div
                className={b('form', 'switchers-title')}
                dangerouslySetInnerHTML={{ __html: locale.withdrawalAllowed }}
              />
              <div className={b('form', 'switchers-item')}>
                <Switcher isActive={withdrawalAllowed} onSwitch={setWithdrawalAllowed} />
              </div>
            </div>
            <div className={b('form', 'switchers-block')}>
              <div
                className={b('form', 'switchers-title')}
                dangerouslySetInnerHTML={{ __html: locale.depositAllowed }}
              />
              <div className={b('form', 'switchers-item')}>
                <Switcher isActive={depositAllowed} onSwitch={setDepositAllowed} />
              </div>
            </div>
          </div>
        )}

        <div className={b('bottom')}>
          <Button color="full-transparent" callBack={history.goBack} text={locale.cancel} size="low" />
          <Button disabled={disabled} type="submit" size="low" text={locale.save} />
        </div>
      </form>
    </div>
  );
};

export default ModifyAgent;
