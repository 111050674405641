import AuthApi from './AuthApi';
import UserApi from './UserApi';
import TreeApi from './TreeApi';
import ReportsApi from './ReportsApi';
import FinanceApi from './FinanceApi';

class Api {
  constructor(baseUrl = '') {
    this.auth = new AuthApi(baseUrl);
    this.user = new UserApi(baseUrl);
    this.tree = new TreeApi(baseUrl);
    this.reports = new ReportsApi(baseUrl);
    this.finance = new FinanceApi(baseUrl);
  }
}

export default Api;
