import React, { useState, useCallback, useEffect } from 'react';
import block from 'bem-cn';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';

import Select from 'components/Select/desktop';
import Button from 'components/Button';
import InputDate from 'components/InputDate/desktop';
import Input from 'components/Input';

import DaysFilter from 'features/reports/view/desktop/DaysFilter';
import useTree from 'hooks/useTree';

import { statusSportBets } from 'features/reports/data';

import { actions } from 'features/reports/redux';

import './FilterBlock.scss';

const b = block('filter-block-player-sport');

const FilterBlock = ({ count, currentPage, onPageClick }) => {
  const { getTreeById } = useTree();

  const locale = useSelector(state => state.locale.locale);
  const dispatch = useDispatch();
  const [date, setDate] = useState({
    beginDate: new Date(dayjs().add(-1, 'week')),
    endDate: new Date(dayjs().add(1, 'day')),
  });
  const [activeDay, setActiveDay] = useState('');
  const [filters, setFilters] = useState({
    user: '',
    couponId: '',
    statusBet: statusSportBets[0],
  });

  const onChangeBeginDate = value => {
    if (!isNaN(value.valueOf())) {
      setDate({ ...date, beginDate: value });
    }

    setActiveDay('');
  };

  const onChangeEndDate = value => {
    if (!isNaN(value.valueOf())) {
      setDate({ ...date, endDate: value });
    }
  };

  const handleUserChange = useCallback(
    e => {
      setFilters({ ...filters, [e.currentTarget.name]: e.currentTarget.value });
    },
    [filters],
  );

  const handleStatusBetChange = value => {
    const statusBet = statusSportBets.find(item => item.value === value);
    setFilters({ ...filters, statusBet: statusBet });
  };

  useEffect(() => {
    handleSearch();
  }, [count, currentPage]);

  const onClickSearch = () => {
    filters.user.length > 0 && getTreeById(filters.user || '');
    handleSearch();
    onPageClick(0);
  };

  const handleSearch = () => {
    const requestedData = {
      username: filters.user,
      couponId: filters.couponId,
      count,
      status: filters.statusBet.value,
      page: currentPage,
      ...date,
    };
    dispatch(actions.getBetsHistory(requestedData));
  };

  const onChangeActiveDay = useCallback(dayFilter => {
    setActiveDay(dayFilter);
    setDate({ ...date, beginDate: new Date(dayjs().add(dayFilter.value, 'day')) });
  }, []);

  return (
    <div className={b()}>
      <div className={b('header')}>{locale.filter}</div>
      <div className={b('top')}>
        <DaysFilter activeDay={activeDay} setActiveDay={onChangeActiveDay} />
        <div className={b('or')}>{locale.or}</div>
        <div className={b('date')}>
          <div className={b('date-row')}>
            <div className={b('title')}>{locale.dateFrom}</div>
            <div className={b('date-input')}>
              <InputDate selected={date.beginDate} onChange={onChangeBeginDate} />
            </div>
          </div>
          <div className={b('date-row')}>
            <div className={b('title')}>{locale.dateTo}</div>
            <div className={b('date-input')}>
              <InputDate selected={date.endDate} onChange={onChangeEndDate} />
            </div>
          </div>
        </div>
      </div>
      <div className={b('bottom')}>
        <div className={b('inputs')}>
          <div className={b('input')}>
            <div className={b('title')}>{locale.player}</div>
            <Input
              size="low"
              value={filters.user}
              callBack={handleUserChange}
              placeholder={locale.player}
              name="user"
            />
            <div className={b('advice')}>{` (${locale.enterTextInLowercaseOnly})`}</div>
          </div>

          <div className={b('input')}>
            <div className={b('title')}>{locale.type}</div>
            <Select
              name="statusBets"
              items={statusSportBets}
              activeItem={filters.statusBet}
              onChange={handleStatusBetChange}
            />
          </div>
        </div>
        <div className={b('button')}>
          <div className={b('title')} style={{ color: 'transparent' }}>
            transparent placeholder
          </div>
          <Button size="low" callBack={() => onClickSearch()} text={locale.acceptFilter} />
        </div>
      </div>
    </div>
  );
};

export default FilterBlock;
