import React, { useState, useCallback, useEffect } from 'react';
import block from 'bem-cn';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';

import Button from 'components/Button';
import InputDate from 'components/InputDate/desktop';
import Input from 'components/Input';
import useTree from 'hooks/useTree';

import DaysFilter from 'features/reports/view/desktop/DaysFilter';

import { actions } from 'features/reports/redux';

import './FilterBlock.scss';

const b = block('filter-block-player-detail');

const FilterBlock = ({ count, currentPage, isMobile, onPageClick }) => {
  const locale = useSelector(state => state.locale.locale);
  const dispatch = useDispatch();
  const { getTreeById } = useTree();

  const [date, setDate] = useState({
    beginDate: new Date(dayjs().add(-1, 'week')),
    endDate: new Date(dayjs().add(1, 'day')),
  });
  const [activeDay, setActiveDay] = useState('');
  const [filters, setFilters] = useState({
    user: '',
  });
  const [isDirectOnly, setIsDirectOnly] = useState(false);

  const onChangeBeginDate = value => {
    if (!isNaN(value.valueOf())) {
      setDate({ ...date, beginDate: value });
    }

    setActiveDay('');
  };

  const onChangeEndDate = value => {
    if (!isNaN(value.valueOf())) {
      setDate({ ...date, endDate: value });
    }
  };

  const handleUserChange = useCallback(
    e => {
      setFilters({ ...filters, [e.currentTarget.name]: e.currentTarget.value });
    },
    [filters],
  );

  useEffect(() => {
    handleSearch();
  }, [count, currentPage]);

  const onClickSearch = () => {
    filters.user.length > 0 && getTreeById(filters.user || '');
    handleSearch();
    onPageClick(0);
  };

  const handleSearch = () => {
    const requestedData = {
      isDirectOnly,
      username: filters.user,
      count,
      page: currentPage,
      ...date,
    };
    dispatch(actions.getStatisticPlayer(requestedData));
  };

  const onChangeActiveDay = useCallback(dayFilter => {
    setActiveDay(dayFilter);
    setDate({ ...date, beginDate: new Date(dayjs().add(dayFilter.value, 'day')) });
  }, []);

  return (
    <div className={b()}>
      <div className={b('header')}>{locale.filter}</div>
      <div className={b('top')}>
        <DaysFilter activeDay={activeDay} setActiveDay={onChangeActiveDay} isMobile={isMobile} />
        <div className={b('or')}>{locale.or}</div>
        <div className={b('date')}>
          <div className={b('date-row')}>
            <div className={b('title')}>{locale.dateFrom}</div>
            <div className={b('date-input')}>
              <InputDate selected={date.beginDate} onChange={onChangeBeginDate} />
            </div>
          </div>
          <div className={b('date-row')}>
            <div className={b('title')}>{locale.dateTo}</div>
            <div className={b('date-input')}>
              <InputDate selected={date.endDate} onChange={onChangeEndDate} />
            </div>
          </div>
        </div>
      </div>
      <div className={b('bottom')}>
        <div className={b('inputs')}>
          <div className={b('input')}>
            <div className={b('title')}>{`${locale.player} / ${locale.agent}`}</div>
            <Input
              size="low"
              value={filters.user}
              callBack={handleUserChange}
              placeholder={`${locale.player} / ${locale.agent}`}
              name="user"
            />
            <div className={b('advice')}>{` (${locale.enterTextInLowercaseOnly})`}</div>
          </div>
        </div>
        <div className={b('button')}>
          <div className={b('title')} style={{ color: 'transparent' }}>
            transparent placeholder
          </div>
          <Button size="low" callBack={() => onClickSearch()} text={locale.acceptFilter} />
        </div>
      </div>
    </div>
  );
};

export default FilterBlock;
