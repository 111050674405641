export const initialState = {
  actionProcessing: false,
  usersList: {
    balanceSum: 0,
    totalCount: 0,
    users: [],
  },
  userInfo: {
    id: 0,
    name: '',
    surname: '',
    username: '',
    email: '',
    role: 0,
    balance: 0,
    bonusBalance: 0,
    currency: '',
    parentAgentId: 0,
    structurePath: '',
    note: '',
    isBanned: false,
    isVisible: true,
    isWithdrawalAccess: true,
    isDepositAccess: true,
    firstVisit: '',
    lastVisit: '',
    structure: '',
  },
  isNeedToRefresh: false,
};
