import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import block from 'bem-cn';

import Button from 'components/Button';

import { getTitlePageFromRoute } from '../../data';

import './Subheader.scss';

const b = block('subheader');
const Subheader = () => {
  const locale = useSelector(state => state.locale.locale);
  const location = useLocation();

  const getTitle = () => {
    return getTitlePageFromRoute.find(el => location.pathname.includes(el.route));
  };

  const currentItem = getTitle();

  if (currentItem?.exception && location.pathname.includes(currentItem.exception)) return null;
  return (
    <div className={b()}>
      <div className={b('second-title')}>{location.pathname === '/' ? '' : locale[currentItem.title]}</div>
      {location.pathname.includes('/users') && (
        <div className={b('buttons')}>
          <Button link="/user/create-agent" text={locale.newAgent} />
          <Button color="transparent" link="/user/create-player" text={locale.newPlayer} />
        </div>
      )}
    </div>
  );
};

export default Subheader;
