import BaseApi from '../BaseApi';
import { FinanceApiConverter } from './FinanceApiConverter';

class FinanceApi extends BaseApi {
  constructor(baseUrl) {
    super(baseUrl);
    this.baseUrl = `${baseUrl}/api`;
    this.converter = new FinanceApiConverter();
  }

  getFinanceHistory = ({ id, requestedBody }) =>
    this.sendQuery(
      this.queryTypes.GET,
      `${this.baseUrl}/agent_admin/user/${id}/finance/history/`,
      requestedBody,
      null,
      this.converter.convertFinanceHistory,
    );

  getFlatPages = ({ lang }) =>
    this.sendQuery(
      this.queryTypes.GET,
      `${this.baseUrl}/site/flatpages/list/${lang}`,
      null,
      null,
      this.converter.convertFlatPages,
    );
}

export default FinanceApi;
