import { actionTypes } from './actions';
import { initialState } from './initial';

export function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ACTION_PROCESSING:
      return {
        ...state,
        actionProcessing: action.payload,
      };
    case actionTypes.CREATE_USER_SUCCESS:
      return {
        ...state,
        actionProcessing: false,
      };

    case actionTypes.GET_USERS_LIST_SUCCESS:
      return {
        ...state,
        usersList: {...action.payload, totalCount: action.payload.users.length === 1 ? 1 : action.payload.totalCount},
        actionProcessing: false,
      };

      case actionTypes.GET_USERS_LIST_FAILED:
      return {
        ...state,
        usersList: {
          balanceSum: 0,
          totalCount: 0,
          users: [],
        },
        actionProcessing: false,
      };

    case actionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        actionProcessing: false,
        newPassword: action.payload,
      };

    case actionTypes.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        actionProcessing: false,
        userInfo: action.payload,
      };

    case actionTypes.USER_INFO_DELETE:
      return {
        ...state,
        actionProcessing: false,
        userInfo: {},
      };

    case actionTypes.DATA_REFRESH: {
      return {
        ...state,
        actionProcessing: false,
        isNeedToRefresh: !state.isNeedToRefresh,
      };
    }

    default:
      return { ...state };
  }
}
