import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { actions } from 'features/users/redux';
import { actions as treeActions } from 'features/tree';

const useTree = () => {
  const dispatch = useDispatch();

  const selectAgent = useCallback(
    id => {
      dispatch(treeActions.selectAgentId(id));
    },
    [dispatch],
  );

  const getUsers = data => {
    return dispatch(actions.getUsersList(data));
  };

  const getTreeById = name => {
    getUsers({ username: name ?? '' }).then(id => {
      if (id) {
        dispatch(treeActions.getUsersByUserId({ id, nesting: [id], name }, true)).finally(() => {
          selectAgent(id);
        });
      }
    });
  };

  return { getTreeById };
};

export default useTree;
