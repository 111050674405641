import React, { useState } from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SVGInline from 'react-svg-inline';

import ArrowSVG from './img/arrow.svg';
import './Select.scss';

const Select = ({ items, activeItem, onChange, placeholder = '', color = '', paginator }) => {
  const b = block('select');
  const locale = useSelector(state => state.locale.locale);
  const [isOpen, changeOpen] = useState(false);
  const itemsList = items.map(item => (
    <li key={item.value} className={b('item', { color })} onClick={() => onChange(item.value)}>
      <div className={b('item-main')}>
        <span className={b('item-text')}>{paginator ? item.name : locale[item.name] || item.name}</span>
        {item.svg && <SVGInline className={b('item-icon').toString()} svg={item.svg} />}
      </div>
    </li>
  ));
  return (
    <div
      className={b({ open: isOpen, color, paginator })}
      onClick={() => changeOpen(!isOpen)}
      onMouseLeave={() => changeOpen(false)}>
      <div className={b('item', { current: true, color })}>
      <span className={b('item-text')}> {locale[activeItem.name] || activeItem.name || placeholder}</span>
        <SVGInline className={b('arrow').toString()} svg={ArrowSVG} />
      </div>
      {isOpen && <ul className={b('items-list', { color })}>{itemsList}</ul>}
    </div>
  );
};

Select.propTypes = {
  items: PropTypes.array.isRequired,
  activeItem: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  color: PropTypes.string,

  onChange: PropTypes.func.isRequired,
};

export default Select;
