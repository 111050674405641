import React from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';

import SVG from 'components/SVG';

import arrowSVG from './img/arrow-right.svg';

import './TableRow.scss';

const TableRow = ({ item, locale }) => {
  const b = block('table-row-cash');

  return (
    <div className={b('tr')}>
      <div className={b('td')}>{item.id}</div>
      <div className={b('td')}>{item.createdAt}</div>
      <div className={b('td')}>{item.operation === 0 ? locale.deposit : locale.withdrawal}</div>
      <div className={b('td')}>{item.initiatorUser}</div>
      <div className={b('td')}>{item.fromUser}</div>
      <div className={b('td')}>{item.toUser}</div>
      <div className={b('td')}>{item.amount}</div>
      <div className={b('td')}>
        {item.balanceFrom}
        <SVG svgProps={{ svg: arrowSVG }} className={b('arrow')} />
        {item.balanceTo}
      </div>
    </div>
  );
};

TableRow.propTypes = {
  item: PropTypes.object.isRequired,
};

export default TableRow;
