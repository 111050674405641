import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import block from 'bem-cn';

import Spinner from 'components/Spinner/view/desktop';

import FilterBlock from './FilterBlock';
import Table from './Table/Table';
import TableProviders from './TableProviders/TableProviders';

import './General.scss';

const b = block('general');

const General = ({ isMobile = false }) => {
  const { actionProcessing } = useSelector(state => state.reports, shallowEqual);
  const statisticProvider = useSelector(state => state.reports.statisticProvider, shallowEqual);

  return (
    <div className={b({ isMobile })}>
      <Spinner isLoading={actionProcessing} />
      <FilterBlock isMobile={isMobile} />
      <Table thItems={Object.keys(statisticProvider.detail)} trItems={[Object.values(statisticProvider.detail)]} />
      <TableProviders thItems={statisticProvider.providers} trItems={statisticProvider.providers} isMobile={isMobile} />
    </div>
  );
};

export default General;
