import React from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';
import { shallowEqual, useSelector } from 'react-redux';

import './TableRow.scss';

const TableRow = ({ items, headers, type = null }) => {
  const b = block('table-row-pagination');
  const locale = useSelector(state => state.locale.locale, shallowEqual);

  return (
    <div className={b('tr')}>
      {items?.map((el, index) => {
        const isHistory = headers[index] === 'status' || headers[index] === 'type';
        return (
          <div key={`${el}_${index}`} className={b('td', { status: isHistory && el })}>
            {isHistory ? locale.statuses[el] : el}
          </div>
        );
      })}
    </div>
  );
};

TableRow.propTypes = {
  items: PropTypes.object.isRequired,
};

export default TableRow;
