import React, { useCallback, useMemo, useState } from 'react';
import block from 'bem-cn';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'features/tree';
import SVG from 'components/SVG';

import arrowSvg from '../img/arrow.svg';
import userSvg from '../img/user.svg';
import line1SVG from '../img/line1.svg';
import line3SVG from '../img/line3.svg';
import line2SVG from '../img/line2.svg';

import './TreeRow.scss';

const b = block('tree-row');

const TreeRow = ({ row, nesting, isLast = false, isChildren = false, idx, level, userList }) => {
  const dispatch = useDispatch();
  const selectedAgentId = useSelector(state => state.tree.selectedAgentId);
  const { id: authId, username } = useSelector(state => state.auth);

  const { userId, subusersCount } = row;

  const [isOpened, setOpened] = useState(row.isOpened ?? (row.childrens?.length ? false : null));

  const handleLoadUsersList = useCallback(() => {
    dispatch(actions.getUsersByUserId({ id: userId, nesting }));
  }, [nesting, dispatch, userId]);

  const selectAgent = useCallback(
    id => {
      dispatch(actions.selectAgentId(id));
    },
    [dispatch],
  );

  const onClick = useCallback(() => {
    selectAgent(userId);
    dispatch(actions.agentClickTrigger());
    if (subusersCount) {
      if (isOpened === null || !isOpened) {
        setOpened(true);
        handleLoadUsersList();
      } else {
        setOpened(prevState => !prevState);
      }
    }
  }, [isOpened, subusersCount, handleLoadUsersList, selectAgent, userId]);

  const childrens = useMemo(
    () =>
      row.childrens?.map((t, idx, arr) => (
        <TreeRow
          key={t.userId}
          nesting={[...nesting, t.userId]}
          isLast={arr.length - 1 === idx}
          isChildren
          row={t}
          idx={idx}
          level={level + 1}
          userList={arr}
        />
      )) || [],
    [row, nesting, level],
  );

  const active = selectedAgentId === userId;

  const currentLineSVG = isLast ? line3SVG : line1SVG;
  const lineType = isLast ? 3 : 1;

  const hasSibling = !!userList[idx + 1];
  const hasIndentBetweenUsers = hasSibling && !!row.childrens;
  let loop = 1;
  const createStraightLines = rowInner => {
    if (!!rowInner.childrens) {
      return rowInner.childrens.map(el => {
        const newTop = 42 * loop;
        loop += 1;
        return (
          <>
            <SVG style={{ top: `${newTop}px` }} svgProps={{ svg: line2SVG }} className={b('line', { type: 2 })} />
            {createStraightLines(el)}
          </>
        );
      });
    }
    return null;
  };
  return (
    <div className={b({ isLast, isChildren })}>
      {hasIndentBetweenUsers && isOpened && createStraightLines(row)}
      <div className={b('parent', { active })} onClick={onClick}>
        {row.userId !== authId && <SVG svgProps={{ svg: currentLineSVG }} className={b('line', { type: lineType })} />}
        <div className={b('parent', 'wrapper')}>
          <SVG svgProps={{ svg: userSvg }} className={b('parent', 'img-user', { opened: isOpened, active })} />
          <span className={b('parent', 'title', { opened: isOpened, active })}>{row.username || username}</span>
        </div>
        {!!row.subusersCount && (
          <SVG svgProps={{ svg: arrowSvg }} className={b('parent', 'img-arrow', { opened: isOpened })} />
        )}
      </div>
      {isOpened && <div className={b('childrens')}>{childrens}</div>}
    </div>
  );
};

TreeRow.propTypes = {};

export default TreeRow;
